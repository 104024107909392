import React, { useState, useEffect } from 'react';
import aboutImage2 from '../../Assets/ap.avif';
import { Helmet } from 'react-helmet';
import teamMember1 from '../../Assets/laksmi.jpg';
import teamMember2 from '../../Assets/anjali.jpg';
import teamMember3 from '../../Assets/Keerthi.jpg';
import teamMember4 from '../../Assets/nizu.jpg';
import teamMember5 from '../../Assets/nidhuna.jpg';
import teamMember6 from '../../Assets/anusree.jpg';
import teamMember7 from '../../Assets/joyal.jpg';
import teamMember8 from '../../Assets/anaz.jpg';
import teamMember9 from '../../Assets/shalu.jpg';
import teamMember10 from '../../Assets/gautham.jpg';
import BgText from '../../Assets/wrk.jpg'
import BgTe from '../../Assets/w.avif'

const teamMembers = [
  { name: 'Shalu.V', role: 'Operations Manager', image: teamMember9 },
  { name: 'Joyal John Aji', role: 'Software Developer', image: teamMember7 },
  { name: 'Sreelakshmi Presadh.k', role: 'Front-end Developer', image: teamMember1 },
  { name: 'Nidhuna', role: 'Front-End Developer', image: teamMember5 },
  { name: 'Anusree', role: 'Front-End  Developer', image: teamMember6 },
  { name: 'Keerthi', role: 'Front-End Developer', image: teamMember3 },
  { name: 'Anjali Ayyappan', role: 'Front-end Developer', image: teamMember2 },
  { name: 'Mohammed Anas', role: 'Back-end Developer', image: teamMember8 },
  { name: 'Nizam T.C', role: 'Back-End Developer', image: teamMember4 },
  { name: 'Gautham Manoj V.P', role: 'UI/UX Designer', image: teamMember10 },
];

const About = () => {
  const [activeMember, setActiveMember] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveMember(prev => (prev + 1) % teamMembers.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <title>About SekenZ | Leaders in Best Digital Marketing & Top IT Solutions</title>
        <meta name="description" content="At SekenZ, we combine the best in digital marketing with top IT solutions to drive your business forward. Our mission is to deliver innovative strategies that make a real difference. Meet the team behind our success and discover the technology and marketing excellence." />
        <meta name="keywords" content="SekenZ, digital marketing, IT solutions, web development, app development, innovative strategies" />
        <meta property="og:title" content="About SekenZ | Leaders in Digital Marketing & IT Solutions" />
        <meta property="og:description" content="SekenZ combines the best in digital marketing with top IT solutions. Discover our innovative strategies, meet our team, and explore our technology and marketing excellence." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sekenz.com/about" />
        <link rel="canonical" href="https://www.sekenz.com/about" />
      </Helmet>

      <div className="relative h-[880px] md:h-[800px] z-0">
        <img 
          src={BgText} 
          alt="Background" 
          className="w-full h-full object-cover"
        />

        <div className="absolute inset-0 flex items-center justify-center md:justify-end p-4 md:p-8">
          <div className="max-w-4xl mb-96">
            <h1 className="text-3xl md:text-5xl text-black font-bold mb-4 md:mb-8">Who we are</h1>
            
            <div className="space-y-4 md:space-y-6 text-gray-500 text-sm md:text-xl text-justify leading-relaxed">
              <p>
                Sekenz Group is a leading web and app development company specializing 
                in custom solutions for businesses worldwide. We deliver innovative, 
                user-friendly, and scalable digital products, ensuring exceptional quality 
                and client satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="flex flex-col md:flex-row items-center justify-between py-12 px-4 md:px-[5%] bg-[#daedfe]">
        <img src={aboutImage2} alt="" className="w-full md:max-w-[35%] rounded-[20px] mb-8 md:mb-0 md:mr-8" data-aos="slide-right" />
        <div className="w-full md:max-w-[50%]">
          <h2 className="text-3xl md:text-5xl text-black font-bold mb-4 md:mb-8">Our Approach</h2>
          <p className="text-base md:text-xl leading-relaxed text-justify text-gray-500 tracking-wide">
            We emphasize innovation and collaboration. We start with detailed planning, create user-centric designs, and use the latest technologies for development. Our Agile methodology ensures flexibility, while rigorous quality assurance guarantees top-notch performance. We provide seamless deployment and continuous support for lasting success.
          </p>
        </div>
      </section>

      <div className="bg-gradient-to-b from-[#0a1930] to-black py-8 md:py-12 px-4 md:px-[5%] text-center text-white text-xl md:text-3xl font-medium tracking-wide leading-relaxed md:h-[200px] shadow-lg">
        <p>
          "Innovation as our compass, collaboration as our fuel. <br className="hidden md:inline" /> Transforming possibilities into realities."
        </p>
      </div>

      <div className="relative h-[880px] md:h-[600px] z-0">
        <img 
          src={BgTe} 
          alt="Background" 
          className="w-full h-full object-cover"
        />

        <div className="absolute inset-0 flex items-center justify-center md:justify-start p-4 md:p-8">
          <div className="  p-6 ">
            <h1 className="text-3xl md:text-5xl text-black font-bold mb-6 md:mb-8">Why Sekenz Group</h1>
              
            <div className="space-y-6 md:space-y-8 max-w-2xl">
              <div className="flex items-start space-x-4">
                <div className="text-blue-500 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                  </svg>
                </div>
                <div>
                  <h2 className="text-lg md:text-xl font-semibold mb-2">Innovative process</h2>
                  <p className="text-sm md:text-base leading-relaxed text-justify text-gray-500 tracking-wide">
                    We believe in keeping creativity at the helm of things at our workplace. Our innovative approach of making no two strategies similar is what makes us unique.
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="text-blue-500 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <div>
                  <h2 className="text-lg md:text-xl font-semibold mb-2">Research focused</h2>
                  <p className="text-sm md:text-base leading-relaxed text-justify text-gray-500 tracking-wide">
                    Crafting transformative solutions through cutting-edge research and unwavering dedication to progress.
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <div className="text-blue-500 mt-1 flex-shrink-0">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 md:h-8 md:w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                </div>
                <div>
                  <h2 className="text-lg md:text-xl font-semibold mb-2">Excellence in delivery</h2>
                  <p className="text-sm md:text-base leading-relaxed text-justify text-gray-500 tracking-wide">
                    We pride ourselves on our commitment to excellence in delivery. From meticulous planning to flawless execution, we prioritize precision and passion in every project.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between p-16 bg-gray-900 text-white w-full min-h-[600px]">
  <div className="md:w-1/2 mb-12 md:mb-0 md:pr-12 flex flex-col justify-center">
    <h2 className="text-5xl font-bold mb-8 flex items-center">
      <svg className="w-12 h-12 mr-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
      </svg>
      Vision
    </h2>
    <p className="text-gray-300 text-xl leading-relaxed">
    At Sekenz, we believe in a future where every brand, no
matter its scale or industry, can excel and shine in the
digital landscape.We aim to pioneer a future where
innovative technology, strategic creativity, and data-driven
insights seamlessly integrate to empower brands to reach
their full potential. We are dedicated to transforming this
vision into a reality, guiding brands to excel in the dynamic
digital landscape.
    </p>
  </div>
  <div className="md:w-1/2 flex flex-col justify-center">
    <h2 className="text-5xl font-bold mb-8 flex items-center">
      <svg className="w-12 h-12 mr-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
      </svg>
      Mission
    </h2>
    <p className="text-gray-300 text-xl leading-relaxed">
    Our mission at Sekenz is to empower brands to succeed inthe digital world by delivering innovative, data-drivensolutions that drive growth and engagement. We arecommitted to helping brands of all sizes navigate thecomplexities of the digital landscape, leveraging cutting-edge technology and creative strategies to achieve lastingimpact and meaningful connections with their audiences.
    </p>
  </div>
</div>

      <section className="p-4 md:p-8 bg-white">
        <h1 className="text-3xl md:text-5xl text-black font-bold text-center mb-6 md:mb-10">Our Team</h1>
        <div className="relative h-[300px] overflow-hidden">
          <div className="flex justify-center items-center h-full">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className={`
                  absolute flex flex-col items-center text-center bg-white rounded-lg shadow-md p-4 w-full max-w-[280px]
                  transition-all duration-1000 ease-in-out transform
                  ${index === activeMember 
                    ? 'opacity-100 translate-x-0' 
                    : 'opacity-0 translate-x-full'
                  }
                `}
              >
                <div
                  className="w-20 h-20 md:w-28 md:h-28 rounded-full bg-cover bg-center mb-4"
                  style={{ backgroundImage: `url(${member.image})` }}
                ></div>
                <h3 className="text-base md:text-lg font-semibold text-gray-800 mt-2 mb-1">{member.name}</h3>
                <p className="text-sm md:text-base text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;