import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import sunfocus_png from '../Assets/sekenznew.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <nav className="bg-white shadow-md w-full rounded-b-3xl transition-all duration-300 hover:rounded-b-none">
        <div className="lg:px-10">
          <div className="flex justify-between items-center h-20">
            <div className="flex-shrink-0 flex items-center pl-4 md:pl-0"> {/* Added left padding for mobile */}
              <img className="h-14 w-auto sm:h-16 md:h-20" src={sunfocus_png} alt="SUNFOCUZ Logo" /> {/* Slightly reduced logo size for mobile */}
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6 lg:space-x-10">
              <Link to="" className="text-gray-800 hover:text-blue-600 transition-colors duration-300 text-xl lg:text-lg
                                relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-600
                                hover:after:w-full after:transition-all after:duration-300">Home</Link>
              <Link to="services" className="text-gray-800 hover:text-blue-600 transition-colors duration-300 text-xl lg:text-lg
                                relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-600
                                hover:after:w-full after:transition-all after:duration-300">Services</Link>
              <Link to="career" className="text-gray-800 hover:text-blue-600 transition-colors duration-300 text-xl lg:text-lg
                                relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-600
                                hover:after:w-full after:transition-all after:duration-300">Career</Link>
              <Link to="about" className="text-gray-800 hover:text-blue-600 transition-colors duration-300 text-xl lg:text-lg
                                relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-600
                                hover:after:w-full after:transition-all after:duration-300">About us</Link>
              <Link to="contact" className="text-gray-800 hover:text-blue-600 transition-colors duration-300 text-xl lg:text-lg
                                relative after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5 after:bg-blue-600
                                hover:after:w-full after:transition-all after:duration-300">Contact us</Link>
            </div>

            <div className="md:hidden flex items-center pr-4"> {/* Added right padding for mobile */}
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"> {/* Reduced size */}
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"> {/* Reduced size */}
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="" className="bg-blue-50 text-gray-800 hover:text-blue-600 block px-3 py-2 rounded-md text-lg font-medium">Home</Link>
            <Link to="services" className="text-gray-800 hover:text-blue-600 hover:bg-blue-50 block px-3 py-2 rounded-md text-lg font-medium">Services</Link>
            <Link to="career" className="text-gray-800 hover:text-blue-600 hover:bg-blue-50 block px-3 py-2 rounded-md text-lg font-medium">Career</Link>
            <Link to="about" className="text-gray-800 hover:text-blue-600 hover:bg-blue-50 block px-3 py-2 rounded-md text-lg font-medium">About us</Link>
            <Link to="contact" className="text-gray-800 hover:text-blue-600 hover:bg-blue-50 block px-3 py-2 rounded-md text-lg font-medium">Contact us</Link>
          </div>
        </div>
      </nav>
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-70 rounded-b-3xl transition-all duration-300 hover:h-2"></div>
    </div>
  );
};

export default Navbar;